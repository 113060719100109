<template>
  <div class="container max-w-screen-md mx-auto m-4">
    <div class="grid grid-cols-1 md:grid-cols-2 text-2xl py-2">
      <div class="text-left">
        <a href="https://spriprüf.de/"
          ><img
            src="@/assets/images/logo_spripruef.png"
            :class="[
              host == 'xn--spriprf-s2a.de' ? 'opacity-100' : 'opacity-50',
            ]"
            alt=""
            class="max-h-10 max-w-full inline"
        /></a>
      </div>
      <div class="text-right">
        <a href="https://spriplan.de/">
          <img
            src="@/assets/images/logo_spriplan.png"
            :class="[host == 'spriplan.de' ? 'opacity-100' : 'opacity-50']"
            alt=""
            class="max-h-10 max-w-full inline"
          />
        </a>
      </div>
    </div>

    <div class="bg-white my-2 rounded shadow">
      <router-view />
    </div>
    <div class="my-1 -mt-1 text-xs text-center text-gray-500">
      <div v-if="this.host == 'xn--spriprf-s2a.de'">
        Angeboten von der SpriPrüf - Prüforganisation für stationären
        Brandschutz, Erhard-Stangl-Ring 14, 84435 Lengdorf<br />
        Icons from <a href="https://items8.com/" target="_blank">Items8</a>
      </div>
      <div v-else>
        Angeboten von der SpriPlan GmbH &amp; Co. KG, Erhard-Stangl-Ring 14,
        84435 Lengdorf<br />
        Icons from <a href="https://items8.com/" target="_blank">Items8</a>
      </div>
    </div>
    <div class="text-xs text-center text-gray-500 my-1">
      <router-link to="/privacy">Datenschutz</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  watch: {
    $route: {
      handler(to) {
        var page = "";
        if (this.host == "spriplan.de") {
          page = "SpriPlan";
        } else {
          page = "SpriPrüf";
        }

        if (to.path == "/") {
          document.title = to.meta.title;
        } else {
          document.title = to.meta.title || page;
        }
      },
      immediate: true,
    },
  },
  computed: {
    host: function () {
      return this.$host;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");

body {
  font-family: "Noto Sans", sans-serif;
  background: rgba(229, 231, 235);
}
</style>
