import Vue from "vue";
import App from "./App.vue";

Vue.config.productionTip = false;

import "@/assets/styles/index.css";

import router from "./router";

Vue.config.productionTip = false;

Vue.prototype.$host = window.location.host.replace("www", "");

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
