import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes_spriplan = [
  {
    path: "/privacy",
    name: "PrivacySpriplan",
    meta: {
      title: "Datenschutzerklärung",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "privacy-plan" */ "../views/Privacy.vue"),
  },
  {
    meta: {
      title: "SpriPlan - Ingenieurbüro für stationären Brandschutz",
    },
    path: "/",
    name: "SpriplanHome",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "spriplan" */ "../views/Spriplan.vue"),
  },
];

const routes_spripruef = [
  {
    path: "/",
    name: "SpripruefHome",
    meta: {
      title: "SpriPrüf - Prüforganisation für stationären Brandschutz",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "spripruef" */ "../views/Spripruef.vue"),
  },
  {
    path: "/privacy",
    name: "PrivacySpripruef",
    meta: {
      title: "Datenschutzerklärung",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "privacy-pruef" */ "../views/PrivacyPruef.vue"
      ),
  },
];

const host = window.location.host.replace("www", "");

var routes = [];

if (host === "spriplan.de") {
  routes = routes_spriplan;
} else {
  routes = routes_spripruef;
}

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

export default router;
